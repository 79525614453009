import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tokenomic"
    }}>{`Tokenomic`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "country-life-cash"
    }}>{`Country Life Cash`}</h2>
    <p><img alt="coin" src={require("./public/images/icon-clc.png")} /></p>
    <p>{`Country Life Cash Token or CLC was deployed on Polygon Chain with ERC-20 standard.`}</p>
    <ul>
      <li parentName="ul">{`Cash ( CLC ) - `}<a parentName="li" {...{
          "href": "https://polygonscan.com/token/0x4483dd44922CC9e8012034b7d7705A6D32F1fda9"
        }}>{`0x4483dd44922CC9e8012034b7d7705A6D32F1fda9`}</a></li>
      <li parentName="ul">{`Entity ( NFT ) - `}<a parentName="li" {...{
          "href": "https://polygonscan.com/token/0x2c1aC4819339a051c6B30F54fb75562dDC5d6442"
        }}>{`0x2c1aC4819339a051c6B30F54fb75562dDC5d6442`}</a></li>
    </ul>
    <p><img alt="chart" src={require("./public/images/meta-chart.png")} /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Pie`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supply`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Percent`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Core Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`250M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Play & Earn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DEX Liquidity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reserved for emergency cases`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10%`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`As mentioned, this game is not for investing. It is for education and fun. We do not expect the price of CLC to go skyrocket, in contrast, we want it to be stable. We do not promise
but will try to keep the price of CLC stable as much as possible`}</p>
    <p>{`How to keep CLC stable? First, we will be the first liquidity provider for USDC and CLC pair. As people come in and take CLC out of the pool, CLC price will rise. At that state, we will swap CLC into the pool in order to keep the price of CLC down. And if it happens oppositingly, we will take action vice versa`}</p>
    <p>{`From time to time, we may provide more liquidity to the pool. This will help the pool grow stabler.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Please note that, we expect the price of CLC to be in range of 30 to 3000 CLC per USDC`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      